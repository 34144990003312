<template>
  <!-- 删除选择原因dialog -->
  <el-dialog
    title=""
    :visible.sync="$store.state.deleteReason.deleteDialogVisible"
    custom-class="manage-el-dialog"
    :before-close="beforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    center
  >
    <div style="text-align: center; margin-bottom: 30px; font-size: 18px">
      <i class="el-icon-delete"></i>
    </div>
    <el-form>
      <div style="margin-bottom: 15px">{{ $t("seletReason") }}：</div>
      <el-select
        v-model="value"
        :placeholder="$t('pleaseChoose')"
        style="width: 100%"
      >
        <el-option
          v-for="item in reasonList"
          :key="item.itemId"
          :label="item.itemVal"
          :value="item.itemId"
        >
        </el-option>
      </el-select>
      <div class="dele-textarea" v-if="value == '-1'" style="margin-top: 15px">
        <el-input
          type="textarea"
          :rows="5"
          :placeholder="$t('pleaseEnterContent')"
          v-model="textarea"
        >
        </el-input>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible">{{ $t("cancel") }}</el-button>
      <el-button type="primary" :loading="loading" @click="confirmDelete">{{
        $t("sure")
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      textarea: "",
      value: "",
      loading: false,
    };
  },
  props: {
    // setDeleteStatus
    reasonList: [],
  },
  methods: {
    //确认删除
    confirmDelete() {
      this.loading = true;
      this.$emit(
        "confirmDelete",
        {
          textarea: this.textarea,
          value: this.value,
        },
        () => {
          this.clearDate();
        }
      );
    },
    //取消操作
    dialogVisible() {
      this.$store.commit("setDeleteDialogVisible", false);
      this.clearDate();
    },
    beforeClose() {
      this.dialogVisible();
    },
    clearDate() {
      this.loading = false;
      this.value = "";
      this.textarea = "";
    },
  },
};
</script>

<style>
.manage-el-dialog {
  width: 600px !important;
}
/* @media screen and (min-width: 100px) {
  .manage-el-dialog {
    width: 70% !important;
  }
}

@media screen and (min-width: 768px) {
  .manage-el-dialog {
    width: 70% !important;
  }
}
@media screen and (min-width: 992px) {
  .manage-el-dialog {
    width: 50% !important;
  }
}
@media screen and (min-width: 1200px) {
  .manage-el-dialog {
    width: 30% !important;
  }
} */
</style>
