var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        visible: _vm.$store.state.deleteReason.deleteDialogVisible,
        "custom-class": "manage-el-dialog",
        "before-close": _vm.beforeClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: ""
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.deleteReason,
            "deleteDialogVisible",
            $event
          )
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "text-align": "center",
            "margin-bottom": "30px",
            "font-size": "18px"
          }
        },
        [_c("i", { staticClass: "el-icon-delete" })]
      ),
      _c(
        "el-form",
        [
          _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
            _vm._v(_vm._s(_vm.$t("seletReason")) + "：")
          ]),
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: _vm.$t("pleaseChoose") },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            _vm._l(_vm.reasonList, function(item) {
              return _c("el-option", {
                key: item.itemId,
                attrs: { label: item.itemVal, value: item.itemId }
              })
            }),
            1
          ),
          _vm.value == "-1"
            ? _c(
                "div",
                {
                  staticClass: "dele-textarea",
                  staticStyle: { "margin-top": "15px" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 5,
                      placeholder: _vm.$t("pleaseEnterContent")
                    },
                    model: {
                      value: _vm.textarea,
                      callback: function($$v) {
                        _vm.textarea = $$v
                      },
                      expression: "textarea"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.dialogVisible } }, [
            _vm._v(_vm._s(_vm.$t("cancel")))
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.confirmDelete }
            },
            [_vm._v(_vm._s(_vm.$t("sure")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }